import React from 'react'
import { Link } from 'gatsby'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
    AnnotationIcon,
    ChatAlt2Icon,
    InboxIcon,
    MenuIcon,
    QuestionMarkCircleIcon,
    XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import '../styles/tailwind.css'

const navigation = [
    { name: 'about', href: '/about' },
    { name: 'what we do', href: '/what-we-do' },
    { name: 'contact', href: '/contact' },
]

const Header = () => {
    return (
        <header className="sticky top-0 z-50 uppercase bg-white shadow-lg">
            <div className="flex items-center justify-between px-4 py-3 pt-3 mx-auto md:justify-start max-w-7xl md:space-x-10 sm:px-6 lg:px-8">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                    <Link to="/">
                        <span className="sr-only">Virgin Transformers</span>
                        <img
                            className=""
                            src="https://res.cloudinary.com/minimum-viable/image/upload/v1620729697/virgintransformers/optimised_web_logo_ht9ymg.svg"
                            alt=""
                        />
                    </Link>
                </div>


                {navigation.map((item) => (
                    <Link
                        key={item.name}
                        href={item.href}
                        className="text-base font-medium text-gray-500 hover:text-gray-900"
                    >
                        {item.name}
                    </Link>
                ))}
            </div>
        </header>

    );
}

export default Header