import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SEO from '../components/SEO'

const Layout = (props) => {
    return (
        <div className="font-body">
            <Header />
            <SEO />
            {props.children}
            <Footer />
        </div>
    )
}

export default Layout