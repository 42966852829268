import React from 'react'
import '../styles/tailwind.css'
import { Link } from 'gatsby'

const navigation = {
    solutions: [
        { name: 'Marketing', href: 'http://minimumviable.africa' },
        { name: 'Analytics', href: '#' },
        { name: 'Commerce', href: '#' },
        { name: 'Insights', href: '#' },
    ],
    support: [
        { name: 'Pricing', href: '#' },
        { name: 'Documentation', href: '#' },
        { name: 'Guides', href: '#' },
        { name: 'API Status', href: '#' },
    ],
    company: [
        { name: 'About', href: '#' },
        { name: 'Blog', href: '#' },
        { name: 'Jobs', href: '#' },
        { name: 'Press', href: '#' },
        { name: 'Partners', href: '#' },
    ],
    legal: [
        { name: 'Claim', href: '#' },
        { name: 'Privacy', href: '#' },
        { name: 'Terms', href: '#' },
    ],
}

export default function Footer() {
    return (
        <footer className="text-gray-200 bg-gray-800" aria-labelledby="footerHeading">
            <h2 id="footerHeading" className="sr-only">
                Footer
        </h2>
            <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-4">
                {/* <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div className="grid grid-cols-2 gap-8 xl:col-span-2">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold tracking-wider uppercase text-grey-400">Solutions</h3>
                                <ul className="mt-4 space-y-4">
                                    {navigation.solutions.map((item) => (
                                        <li key={item.name}>
                                            <span className="text-base text-grey-300 hover:text-white"> <Link to={item.href}>{item.name}</Link>
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm font-semibold tracking-wider uppercase text-grey-400">Support</h3>
                                <ul className="mt-4 space-y-4">
                                    {navigation.support.map((item) => (
                                        <li key={item.name}>
                                            <a href={item.href} className="text-base text-grey-300 hover:text-white">
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold tracking-wider uppercase text-grey-400">Company</h3>
                                <ul className="mt-4 space-y-4">
                                    {navigation.company.map((item) => (
                                        <li key={item.name}>
                                            <a href={item.href} className="text-base text-grey-300 hover:text-white">
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm font-semibold tracking-wider uppercase text-grey-400">Legal</h3>
                                <ul className="mt-4 space-y-4">
                                    {navigation.legal.map((item) => (
                                        <li key={item.name}>
                                            <a href={item.href} className="text-base text-grey-300 hover:text-white">
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 xl:mt-0">
                        <h3 className="text-sm font-semibold tracking-wider uppercase text-grey-400">
                            Subscribe to our newsletter
              </h3>
                        <p className="mt-4 text-base text-grey-300">
                            The latest news, articles, and resources, sent to your inbox weekly.
              </p>
                        <form className="mt-4 sm:flex sm:max-w-md">
                            <label htmlFor="emailAddress" className="sr-only">
                                Email address
                </label>
                            <input
                                type="email"
                                name="emailAddress"
                                id="emailAddress"
                                autoComplete="email"
                                required
                                className="w-full min-w-0 px-4 py-2 text-base text-gray-900 placeholder-gray-500 bg-white border border-transparent rounded-md appearance-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                                placeholder="Enter your email"
                            />
                            <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                                <button
                                    type="submit"
                                    className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                                >
                                    Subscribe
                  </button>
                            </div>
                        </form>
                    </div>
                </div> */}
                <div className="mt-4 border-t border-grey-700 md:flex md:items-center md:justify-between">
                    <div className="flex space-x-6 md:order-2 text-grey-400">
                        <p>Made with <span className="text-red-500">&#9829;</span> by <span className="underline hover:text-white"><a href="http://minimumviable.africa" target="_blank" rel="noreferrer">Minimum Viable</a></span></p>
                    </div>
                    <p className="pt-4 mt-8 text-base text-grey-400 md:mt-0 md:order-1">
                        &copy; 2021 Virgin Transformers. All rights reserved.
            </p>
                </div>
            </div>
        </footer>
    )
}
